<template>
<div class="content-wrapper">
    <header class="page-header">
        <h2>Uploads</h2>
    </header>

    <div class="components">
        <div class="component upload-component">
            <header>
                <h3>Bulk uploads</h3>
            </header>
            <div class="form-group">
                <h4>What type of files are you uploading?</h4>
                <div class="radio-line">
                    <label><input type="radio" v-model="fileType" value="fhwapo" /><span>FHWA - PO</span></label>
                    <label><input type="radio" v-model="fileType" value="fhwawo" /><span>FHWA - WO</span></label>
                    <label><input type="radio" v-model="fileType" value="reg" /><span>Registration</span></label>
                </div>
            </div>
            <file-input :fileTypes="['.zip']" name="bulk-upload" @change="updateValue" :multiple="true">
                <template v-slot:label>
                    <span>Select a zip containing <a @click.prevent="infoOpen = !infoOpen">properly-named files</a></span> 
                    <div class="accordion" :class="{'open': infoOpen}">
                        <div>
                            <p>Some sort of clarity around what the properly named files means</p>
                        </div>
                    </div>
                </template>
            </file-input>
            <div class="form-controls">
                <button>Run bulk upload</button>
            </div>
        </div>

        <div class="component upload-component">
            <header>
                <h3>Zolstar upload</h3>
            </header>
            <file-input 
                :fileTypes="['.csv', '.zip']" 
                name="bulk-upload" 
                label="Select a CSV or a zip file containing a CSV file" 
                @change="updateValue(zolstarFile)"
                ></file-input>
            <div class="form-controls">
                <button>Ask Zolstar</button>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import FileInput from '../components/inputs/FileInput.vue';

var utilityMixin = {
    methods: {
        orList: function(list){
            var orListBuilder = [...list];
            if (orListBuilder.length > 1){
                orListBuilder[orListBuilder.length - 1] = "or " + orListBuilder[orListBuilder.length - 1];
            }
            if (orListBuilder.length === 2) {
                return orListBuilder[0] + " " + orListBuilder[1];
            } 
            return orListBuilder.join(", ");
        }
    }
}

export default {
    components: {
        FileInput,
    },
    mixins: [utilityMixin],
    data: function(){
        return {
            fileType: 'fhwapo',
            infoOpen: false,
            bulkFile: null,
            zolstarFile: null,
        }
    },
    methods: {
        updateValue: function(){
            console.log(arguments);            
        }
    }
}
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";
.upload-component{
    width: 100%;
    max-width: 500px;
}
</style>