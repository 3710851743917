<template>
    <div class="form-group file-input">
        <label>
            <slot name="label" v-if="label || !!(this.$slots.label && this.$slots.label()[0].children.length)"><span>{{label}}</span></slot>
            <div class="form-file" 
                @dragenter.stop.prevent="null" 
                @dragover.stop.prevent="null"
                @drop.stop.prevent="dropFiles"
                >
                <input type="file" 
                    @change="fileChange" 
                    :name="name" 
                    :accept="fileTypes.join(',')" 
                    :required="required" 
                    v-bind="(multiple ? {multiple: 'multiple'} : {})" />
                <span>
                    <i class="fas fa-cloud-download-alt"></i> Drag your {{orList(fileTypes)}} {{(multiple ? 'files': 'file')}} here <br />
                    <span>Browse...</span>
                </span>
            </div>
        </label>
        <ul class="file-list">
            <li v-for="file in files" :key="file.name">
                <span><i class="far fa-file"></i> {{file.name}} {{Math.floor(file.size/1024/1024 * 100) / 100}}MB</span>
                <span class="remove-file" @click="removeFile(file)"><i class="fa fa-times"></i></span>
            </li>
        </ul>
    </div>
</template>

<script>
var utilityMixin = {
    methods: {
        orList: function(list){
            var orListBuilder = [...list];
            if (orListBuilder.length > 1){
                orListBuilder[orListBuilder.length - 1] = "or " + orListBuilder[orListBuilder.length - 1];
            }
            if (orListBuilder.length === 2) {
                return orListBuilder[0] + " " + orListBuilder[1];
            } 
            return orListBuilder.join(", ");
        }
    }
}

export default {
    props: [
        'label',
        'fileTypes',
        'name',
        'multiple',
        'required'
    ],
    mixins: [utilityMixin],
    data: function(){
        return {
            fileName: null,
            fileSize: 0,
            files: []
        }
    },
    methods: {
        fileChange: function(event){
            this.addFiles(event.target.files);
        },
        dropFiles: function(event){
            this.addFiles(event.dataTransfer.files);
        },
        addFiles: function(files){
            for (var file in files){
                if (Object.prototype.hasOwnProperty.call(files, file) && !this.files.includes(file)){
                    this.files.push(files[file]);
                }
            }
            this.$emit('change', this.name, this.files);
        },
        removeFile: function(file){
            console.log(this.files);
            let i = this.files.indexOf(file);
            if (i !== -1){
                this.files.splice(i, 1);
                this.$emit('change', this.name, this.files);
            }
        }
    }
}
</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";

.form-file{
    position: relative;
    display: block;
    height: 140px;
    width: 100%;
    display: flex;
    background: nth($grays, 10);
    border: 1px dashed nth($grays, 7);
    align-items: center;
    justify-content: center;
    border-radius: $input-radius;
    box-shadow: $whitener;
    transition: $transition-standard;

    > span {
        > span {
            color: #1D7C5B;
            text-decoration: underline;

        }
    }
    input{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;

        // &:valid + span {
        //     background: nth($greens, 10);
        // }

        // &:valid + span > .before-select,
        // + span > .selected{
        //     width: 0;
        //     overflow: hidden;
        // }
        // &:valid + span > .selected{
        //     width: auto;
        // }
    }      
}
.file-list {
    list-style-type: none;
    padding: 0;


    li{
        display: block;
        padding: 0 $space-standard;
        line-height: 33px;
        border: 1px solid nth($grays, 7);
        margin: 0 0 $space-standard;
        display: flex;
        justify-content: space-between;
        border-radius: $input-radius;
        box-shadow: $shadow-standard;
        background: $white;

    }

    .remove-file {
        cursor: pointer;
    }
}
</style>